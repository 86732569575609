<!--
 * @Descripttion: 用户注册页
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 08:51:17
-->

<template>
	<div class="contariner">
		<h2 class="home">智采竞价系统</h2>
		<div class="form-container">
			<el-form ref="form" :model="store" :rules="rules" :hide-required-asterisk="true" label-width="150px" class="register">
				<h1 class="register-title">欢迎使用智采竞价系统</h1>
				<el-form-item label="供应商名称" prop="storeName">
					<el-input v-model="store.storeName" placeholder="请输入营业执照中的公司全称"></el-input>
				</el-form-item>
				<el-form-item label="统一社会信用代码"  prop="uscc">
					<el-input v-model="store.uscc" placeholder="请输入统一社会信用代码18位"></el-input>
				</el-form-item>
				<el-form-item label="联系人" prop="linkMan">
					<el-input v-model="store.linkMan" placeholder="请输入联系人"></el-input>
				</el-form-item>
				<el-form-item label="联系人手机号" prop="linkPhone">
					<el-input v-model="store.linkPhone" placeholder="联系人手机号"></el-input>
				</el-form-item>
				<el-form-item label="联系地址" prop="linkAddr">
					<el-input v-model="store.linkAddr" placeholder="请输入联系地址"></el-input>
				</el-form-item>
				<el-form-item v-if="existsEwStore">
					<el-button type="primary" @click="onSubmit">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {httpPost} from "@/utils/http"
	import global from "@/common/global"
	export default {
		data() {
			// 校验统一社会信用代码
			var codeReg = (rule,value, callback) =>{
				if(/[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/.test(value))
					callback()
				else
					callback(new Error('统一社会信用代码格式不正确'))
			};
			return {
				store:{
					storeName:"",
					uscc:"",
					linkMan:"",
					linkPhone:"",
					linkAddr:"",
				},
				rules:{
					storeName:[
						{required: true, message:'请输入采购人名称', trigger:'blur'},
					],
					uscc: [
						{required: true, message:'请输入统一社会信用代码', trigger:'blur'},
						{min:18, max:18, message:'请输入18位的统一社会信用代码', trigger:'blur'},
						{validator: codeReg,trigger:'blur'}
					],
					linkMan: [
						{required: true, message:'请输入联系人', trigger:'blur'}
					],
					linkPhone: [
						{required: true, message:'请输入联系人手机号', trigger:'blur'}
					],
					linkAddr: [
						{required: true, message:'请输入地址', trigger:'blur'}
					]
				},
				ewStore:{},
				existsEwStore:false
			}
		},
		created(){
			//从缓存获取ew_store
			let ewStoreStr = localStorage.getItem("corp_bid_ew_store")
			if(ewStoreStr!=""){
				this.ewStore = JSON.parse(ewStoreStr)
				this.existsEwStore = true
			}
		},
		methods: {
			onSubmit() {
				let that = this
				this.$refs['form'].validate(valid=>{
					if(valid){
						this.store.id = this.ewStore.id
						//提交
						let url = global.gateway+"/ewbid/store/tbEwStoreExternal/finishedEwStoreExternal"
						httpPost(url,this.store).then(res=>{
							if(res.status=="200"&&res.data.success){
								//设置新的缓存
								localStorage.setItem("corp_bid_ew_store",JSON.stringify(that.ewStore))
								//跳转到home页
								that.$router.replace({name:"home"})
							}else{
								that.$message({ type: "error", message: res.data.message,duration:5000 });
								// alert(res.data.message)
							}
						}).catch(error=>{
							console.log(error)
						})
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.contariner {
		height: 100vh;
		background-color: #F5F5F5;

		.home {
			color: #00CCB2;
			font-size: 20px;
			line-height: 50px;
			height: 50px;
			width: 100%;
			padding-left: 15px;
			background-color: #fff;
		}

		.form-container {
			margin: 30px 50px 30px 50px;
			padding-bottom: 50px;
			background-color: #fff;
			border-radius: 10px;

			.register {
				width: 600px;
				margin: 0 auto;
				border-radius: 10px;

				.register-title {
					font-size: 40px;
					font-weight: bold;
					text-align: center;
					padding: 60px 0;
				}
			}
		}
	}
</style>
